.e-richtexteditor .e-rte-content .e-content,
.e-richtexteditor .e-source-content .e-content {
    font-family: "Source Sans 3";
    font-weight: 500;
}

.e-richtexteditor .e-rte-content .e-content h1,
.e-richtexteditor .e-source-content .e-content h1 {
    font-weight: 700;
    font-size: xx-large;
    margin-bottom: 20px;
}

.e-richtexteditor .e-rte-content .e-content h2,
.e-richtexteditor .e-source-content .e-content h2 {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: x-large;
}
.e-richtexteditor .e-rte-content .e-content h3,
.e-richtexteditor .e-source-content .e-content h3 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: large;
}

.e-richtexteditor .e-rte-content .e-content h1 strong,
.e-richtexteditor .e-source-content .e-content h1 strong {
    font-weight: 800;
}

.e-richtexteditor .e-rte-content .e-content h2 strong,
.e-richtexteditor .e-source-content .e-content h2 strong {
    font-weight: 700;
}
.e-richtexteditor .e-rte-content .e-content h3 strong,
.e-richtexteditor .e-source-content .e-content h3 strong {
    font-weight: 700;
}

.e-richtexteditor .e-rte-content .e-content p strong,
.e-richtexteditor .e-source-content .e-content p strong {
    font-weight: 800;
}

.e-richtexteditor .e-rte-content .e-content p,
.e-richtexteditor .e-source-content .e-content p {
    margin-bottom: 10px !important;
}

.e-richtexteditor .e-rte-content .e-content a,
.e-richtexteditor .e-source-content .e-content a {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
}

.e-richtexteditor .e-rte-content .e-content ul,
.e-richtexteditor .e-source-content .e-content ul {
    margin-left: 30px !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
}

.e-richtexteditor .e-rte-content .e-content li,
.e-richtexteditor .e-source-content .e-content li {
    margin-bottom: 0px !important;
}

.e-richtexteditor .e-rte-content .e-content li strong,
.e-richtexteditor .e-source-content .e-content li strong {
    font-weight: 600;
}

.e-richtexteditor .e-rte-content .e-content ol,
.e-richtexteditor .e-source-content .e-content ol {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 30px !important;
}

.e-richtexteditor .e-rte-content .e-content pre,
.e-richtexteditor .e-source-content .e-content pre {
    font-size: 15px;
    line-height: 1.73em;
    padding: 4px 8px;
    background: #eeeeee;
    font-family: "Source Code Pro",monospace;
    font-weight: 500;
    color: #000;
}

.e-richtexteditor-red {
    color:red;
}

.e-paragraph {
    font-family: "Sora";
    font-weight: 300;
}
.e-rte-image {
    cursor: default;
}
/* .e-rte-image {
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.25);
} */

.e-h1 {
    font-family: "Open Sans";
    font-weight: 700;
    font-size: xx-large;
}
.e-h2 {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: x-large;
}
.e-h3 {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: large;
}
