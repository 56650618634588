.dossier-content li, .dossier-content ul {
    margin-left: 15px;
}

.forum-post ol {
    list-style-type: decimal;
    margin-left: 20px;
}

/* Set lines spacing to 5px for forum-post */
.forum-post {
    line-height: 1.7;
    font-weight: 500;
}

/* Set return between lines in forum post height to larger */
.forum-post p {
    margin-bottom: 10px;
    font-weight: 500;
}

.forum-post a {
    color: #007bff;
    font-weight: 600;
}