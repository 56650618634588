/* .item must be first */
.item {
    align-items: center;
    color: #00707f;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 25px;
    justify-content: center;
    width: 25px;
}
  
.disabled-page {
    color: #808e9b;
    cursor: default !important;
}

.active {
    border-radius: 20px;
    color: white;
    background-color: #00707f;
}

.previous {
    font-weight: 700;
    font-size: 14px;
    height: 60px;
    width: 260px;
}

.next {
    font-weight: 700;
    font-size: 14px;
    height: 60px;
    width: 250px;
}

.pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: center;
    list-style-type: none;
}

.pagination-page {
    font-weight: 800;
}
