/* @import "../node_modules/@syncfusion/ej2-base/styles/material.css"; */
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
/* @import "../node_modules/@syncfusion/ej2/material.css"; */

html {
  overflow: -moz-scrollbars-none !important;
  scrollbar-width: none !important;
}

body {
  margin: 0;
  font-family: 'Source Sans 3' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfcfe !important;
  scroll-behavior: smooth !important;
  overflow: auto;
  scrollbar-width: none !important;
  color: #4a4a4a !important;
}

body::-webkit-scrollbar {
  display: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.prettyOption{
  font-family: 'Source Sans 3', "Source Sans Pro", "Arial" !important;
}